import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Table } from "react-bootstrap"

const Cameras = () => {
  return (
    <Layout>
      <SEO title="" description="" />
      <div className="page-headline" id="forCameras">
        <div className="container">
          <div className="section-heading text-center">
            <h1 className="font-weight-bold text-uppercase flair">
              <strong>Cameras</strong>
            </h1>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <Table responsive>
            <tbody>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">Cerberus</h4>
                  <p>
                    Manned entry, hand-held camera which connects directly to
                    your mainline cable. Includes full duplex two-way
                    communication, high intensity LED lighting, and safety
                    harness.
                  </p>
                </td>
                <td>{/*for image*/}</td>
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">HYDRUS</h4>
                  <p>
                    The HYDRUS is a small diameter color, axial view push
                    camera. Inspect small diameters (2” and up) with incredible
                    picture clarity.
                  </p>
                </td>
                {/* <td>for image</td> */}
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">JUNO</h4>
                  Small diameter axial view push camera. Integrated location
                  transmitter, auto-uprighting and bright LEDs for 4” and up.
                </td>
                {/* <td>for image</td> */}
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">NANO</h4>
                  The world’s smallest pan & tilt, lateral, and mainline camera.
                  The NANO is pushrod, tractor or lateral launch capable and has
                  many features including: laser measurement, integrated
                  location transmitter, bright LED lights, pressure regulation,
                  and a full pan and tilt functionality. While the NANO is
                  capable of traveling in pipelines as small as 2″ in diameter,
                  you will not be giving up power or flexibility with this
                  camera.
                </td>
                {/* <td>for image</td> */}
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">NANO-L</h4>
                  The NANO-L is perfect for LISY Lateral Launching. The smaller
                  camera, when used with our more flexible pushrods, will allow
                  you to more easily launch “backwards” into laterals when
                  traveling agains the flow. The smaller size also means you can
                  push around obstacles and pipe bends much easier.
                </td>
                {/* <td>for image</td> */}
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">ORION Zoom</h4>
                  The Orion Zoom is the workhorse of our camera line. Deploy as
                  a push camera or on a mainline tractor system in pipelines 4”
                  and up. Pan & Tilt, integrated location transmitter,
                  auto-uprighting, auto-iris, and adjustable focus. The ultimate
                  in flexibility and modularity.
                </td>
                {/* <td>for image</td> */}
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">ORION-L Zoom</h4>
                  The ORION-L Zoom adds our unique lateral navigating stick,
                  nicknamed “Kiel stick” for the city in which it was invented.
                  This camera allows you to choose direction and “steer” into
                  and around pipe fittings. Perfect for industrial applications
                  or lateral launching. Watch the{" "}
                  <a
                    href="http://youtu.be/CW7xdEhW_1w"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ORION-L in action
                  </a>
                  .
                </td>
                {/* <td>for image</td> */}
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">ORPHEUS 2.0</h4>
                  This new ORPHEUS 2.0 is a mainline and a large diameter
                  inspection camera that moves beyond the specifications of its
                  predecessor. With more powerful zoom, higher picture quality,
                  greater lighting, and an increased deployment range, this
                  newly designed camera can easily take on any pipeline 6″ or
                  larger!{" "}
                </td>
                {/* <td>for image</td> */}
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">ORPHEUS HD</h4>
                  Designed from the ground up to be 100% High Definition, the
                  ORPHEUS HD provides the clearest, most detailed images of any
                  camera available for pipeline inspetion. With 10x optical zoom
                  and a Full HD CMOS imager, this camera is capable of filming
                  30 fps at 1920×1080. You will be able to leverage the quality
                  of your service like never before with the ORPHEUS HD. Watch
                  the{" "}
                  <a
                    href="http://www.youtube.com/watch?v=f39qvgLpmg4&feature=share&list=UUdNJGz945QkWTkGB3HeQEDA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ORPHEUS HD in action
                  </a>
                </td>
                {/* <td>for image</td> */}
              </tr>
              <tr>
                <td>
                  <h4 className="text-uppercase mt-0">POLARIS</h4>
                  Designed for inspecting smaller diameter pipes, this camera
                  features include a 3-axis range of movement that enables the
                  unit to choose the direction of travel when encountering tees
                  or wyes. Other features include upright picture control, pan –
                  tilt- and angle control, wide angle view (120 degrees) and a
                  built in laser for measurement. Watch the{" "}
                  <a
                    href="http://youtu.be/-zam3YvLP70"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    POLARIS in action
                  </a>
                </td>
                {/* <td>for image</td> */}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </Layout>
  )
}

export default Cameras
